var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title":_vm.categoryModal.type == 'edit' ? 'Editar Categoria': 'Crear Categoria',"modal-class":"modal-primary","hide-footer":"","size":"lg","loading":""},on:{"hidden":_vm.close},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(true)?_c('validation-observer',{ref:"checkForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nombre")])]),_c('validation-provider',{attrs:{"name":"name","vid":"name","rules":"max:100|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{class:{ 'border-danger rounded': errors[0] },attrs:{"type":"text","placeholder":"Ingrese el nombre de la categoría","disabled":_vm.disableAll},model:{value:(_vm.category.name),callback:function ($$v) {_vm.$set(_vm.category, "name", $$v)},expression:"category.name"}})],1)]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"color"}},[_vm._v("Color")])]),_c('validation-provider',{attrs:{"name":"color","vid":"color","rules":_vm.type === 'optional' ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{class:{ 'border-danger rounded': errors[0] },attrs:{"type":"color","placeholder":"Ingrese el color de la categoría","disabled":_vm.disableAll},model:{value:(_vm.category.color),callback:function ($$v) {_vm.$set(_vm.category, "color", $$v)},expression:"category.color"}})],1)]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Descripción")])]),_c('validation-provider',{attrs:{"name":"description","vid":"description","rules":_vm.type === 'optional' ? 'max:255' : 'max:255|required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-textarea',{class:{ 'border-danger rounded': errors[0] },attrs:{"type":"text","placeholder":"Ingrese la descripción de la categoria","disabled":_vm.disableAll,"rows":3},model:{value:(_vm.category.description),callback:function ($$v) {_vm.$set(_vm.category, "description", $$v)},expression:"category.description"}})],1)]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"image"}},[_vm._v("Imagen")])]),_c('validation-provider',{attrs:{"name":"image","vid":"image","rules":_vm.type === 'optional' ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-file',{class:{ 'border-danger rounded': errors[0] },attrs:{"type":"text","placeholder":"Ingrese una imagen","disabled":_vm.disableAll},model:{value:(_vm.category.image),callback:function ($$v) {_vm.$set(_vm.category, "image", $$v)},expression:"category.image"}})],1)]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mx-1",attrs:{"type":"submit","variant":"light","disabled":_vm.disableAll},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),(!_vm.loading)?_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.disableAll},on:{"click":_vm.registerCategory}},[_vm._v(" "+_vm._s(_vm.categoryModal.type == 'edit' ? 'Actualizar': 'Guardar')+" ")]):_c('b-button',{attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1)],1)])],1)]}}],null,false,3818801520)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }